var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"pa-0",staticStyle:{"z-index":"6"},attrs:{"value":_vm.isRightSidebarOpen,"app":"","right":"","width":"300"},on:{"input":_vm.SET_RIGHT_SIDEBAR_OPEN},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[(Object.keys(_vm.GET_USERS).length != 1)?_c('v-list-item-subtitle',{staticClass:"participant-count"},[_vm._v(" "+_vm._s(Object.keys(_vm.GET_USERS).length)+" people ")]):_c('v-list-item-subtitle',{staticClass:"participant-count"},[_vm._v(" It's just you, invite some friends ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.DISCONNECT_AND_NAVIGATE_HOME}},[_c('v-icon',[_vm._v("exit_to_app")])],1)],1)],1),_c('v-list-item',{attrs:{"dense":""}},[(_vm.AM_I_HOST)?_c('v-switch',{staticClass:"pa-0 ma-0",attrs:{"hide-details":"","label":"Party Pausing","input-value":_vm.IS_PARTY_PAUSING_ENABLED},on:{"change":_vm.SEND_SET_PARTY_PAUSING_ENABLED}}):_vm._e(),(!_vm.AM_I_HOST && _vm.GET_HOST_USER.state === 'stopped')?_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Waiting for "+_vm._s(_vm.GET_HOST_USER ? _vm.GET_HOST_USER.username : 'host')+" to start ")])],1):_vm._e()],1),(_vm.AM_I_HOST)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-switch',{staticClass:"pa-0 ma-0",attrs:{"hide-details":"","label":"Auto Host","input-value":_vm.IS_AUTO_HOST_ENABLED},on:{"change":_vm.SEND_SET_AUTO_HOST_ENABLED}})],1)]}}],null,false,188452496)},[_c('span',[_vm._v("Automatically transfers host to other users when they play something new")])]):_vm._e(),((!_vm.AM_I_HOST || _vm.usingPlexClient)
        && _vm.GET_HOST_USER && _vm.GET_HOST_USER.state !== 'stopped')?_c('v-list-item',{attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":"rgb(44, 44, 49)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":!_vm.IS_PARTY_PAUSING_ENABLED},on:{"click":function($event){return _vm.sendPartyPause(_vm.GET_HOST_USER.state === 'playing')}}},'v-btn',attrs,false),on),[(_vm.GET_HOST_USER.state === 'playing')?_c('v-icon',[_vm._v(" pause ")]):_c('v-icon',[_vm._v(" play_arrow ")])],1)]}}],null,false,3077870132)},[_c('span',[_vm._v("Party Pausing is currently "+_vm._s(_vm.IS_PARTY_PAUSING_ENABLED ? 'enabled' : 'disabled')+" by the host")])])],1):_vm._e(),_c('v-divider')]},proxy:true},{key:"append",fn:function(){return [_c('MessageInput')]},proxy:true}])},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('UserList'),_c('v-divider'),_c('MessageList',{staticClass:"messages"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }